import React from "react";
import './DebtMonthlySavings.scss'


function DebtMonthlySavings(props) {

  const { savingsDebt } = props;

  if (savingsDebt > 0) {
  return (
    <>
    <div id="MonthlySavingsBox">
    <h2 id="LTVTitle"> Estimated Monthly Savings</h2>
    <h3 id="calcValue">${savingsDebt.toLocaleString()}</h3>
    </div>
    </>
  );
}
else return (
  <>
    <div id="MonthlySavingsBoxNegative">
    <h2 id="LTVTitle"> Estimated Monthly Savings</h2>
    <h3 id="calcValue">${savingsDebt.toLocaleString()}</h3>
    </div>
    </>
);
}
export default DebtMonthlySavings;
